import {
	post
} from '@/http'

// 商品详情
export function getNoLineCart(data) {
	return new Promise((resolve, reject) => {
		post('/ShoppingCart/GetNoLineCartByBarCode', data)
			.then(res => {
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
	})
}

// 我的购物车列表
export function getMyCart(data) {
	return new Promise((resolve, reject) => {
		post('/ShoppingCart/GetMyCart', data)
			.then(res => {
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
	})
}

// 删除购物车
export function removeCart(data) {
	return new Promise((resolve, reject) => {
		post('/ShoppingCart/RemoveCart', data)
			.then(res => {
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
	})
}

// 添加收藏
export function addCollect(data) {
	return new Promise((resolve, reject) => {
		post('/Collect/AddCollect', data)
			.then(res => {
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
	})
}

export function removeAllCart(data) {
	return new Promise((resolve, reject) => {
		post('/ShoppingCart/RemoveAllCart', data)
			.then(res => {
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
	})
}

// 获取用户基本信息
export function getUserInfo(data) {
	return new Promise((resolve, reject) => {
		post('/Users/GetUserInfo', data)
			.then(res => {
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
	})
}
