<template>
	<div>
		<div class="cart-main">
			<div class="breadcrumb">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item><span style="cursor: pointer" @click="$router.push({ path: '/' })">首页</span></el-breadcrumb-item>
					<el-breadcrumb-item>购物车</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="is-login" v-if="!isLogin">
				您还没有登录！登录后购物车的商品将保存到您的帐号中
				<button @click="toLogin">立即登录</button>
			</div>
			<div class="whole">
				<div class="whole-left">全部商品 {{ cardNumber }}</div>
				<div class="whole-right">
					<div class="coupon piliang">
						<el-upload
							:action="baseURL + '/ShoppingCart/UploadInfo'"
							:show-file-list="false"
							:limit="1"
							:multiple="false"
							:file-list="fileList"
							accept="xlsx"
							:on-success="upSuccess"
							:on-error="upError"
						>
							<div class="p-btn">批量采购导入</div>
						</el-upload>

						<div class="p-ico">
							<el-popover placement="bottom" title="" width="200" trigger="hover" content="" v-model="plVisible">
								<div>当已知零件号和采购数量时，可批量导入；无需一个商品一个商品添加购物车。</div>
								<el-button type="text" @click="downloadTemplate">点击下载</el-button>
								<i class="iconfont iconwenhao" slot="reference"></i>
							</el-popover>
						</div>
					</div>
					<div class="coupon">
						<el-popover v-model="popover" placement="bottom-end" width="350" trigger="click">
							<div><div class="coupon-title">优惠券列表</div></div>
							<div class="coupon-border"></div>
							<div>
								<div class="coupon-list" v-for="(item, index) in disList" :key="index" @click="changeCoupon(item)">
									<div class="coupon-list-left">￥{{ item.Money }}</div>
									<div class="coupon-list-center">
										<div v-if="item.MinPrice > 0">满{{ item.MinPrice }}减{{ item.Money }}</div>
										<div v-else>通用{{ item.Money }}</div>
										<div class="coupon-list-center-bottom">有效期至{{ item.OverTime }}</div>
									</div>
									<div class="coupon-list-right">
										<div v-if="item.State != 1">已领取</div>
										<div v-else class="receive" @click="handleAddCoupon(item)">领取</div>
									</div>
								</div>
							</div>
							<img slot="reference" style="width: 100px" src="../../assets/coupon.png" alt="" />
						</el-popover>
					</div>
				</div>
			</div>
			<div>
				<div class="card-top">
					<div class="w-50"><el-checkbox @change="changeCheckboxAll" v-model="checked"></el-checkbox></div>
					<div class="w-150">全选</div>
					<div class="w-400">商品信息</div>
					<div class="w-200">单价(元)</div>
					<div class="w-200" style="padding-left: 46px;box-sizing: border-box;">数量</div>
					<div class="w-200">小计(元)</div>
					<div class="w-200">操作</div>
				</div>
				<div v-for="(item, index) in cartList" :key="index">
					<div class="card-content">
						<div class="w-50"><el-checkbox @change="changeCheckboxSingle(item, item['checked'])" v-model="item['checked']"></el-checkbox></div>
						<div class="w-400">
							<i class="iconfont iconshuishuai-01"></i>
							<span v-if="item.Tax == 0.13">实物类商品</span>
							<span v-else>服务类商品</span>
						</div>
					</div>
					<div class="card-list" v-for="(j, i) in item.List" :key="i">
						<div class="w-50"><el-checkbox v-model="j['checked']" @change="changeCheckbox(j, item)"></el-checkbox></div>
						<div class="w-150"><img style="    cursor: pointer;" :src="pic + j.Picture" alt="" @click="handleTo(j)" /></div>
						<div class="w-400">
							<div class="proName" style="    cursor: pointer;" @click="handleTo(j)">{{ j.ProName }}</div>
							<div class="sku">SKU {{ j.BarCode }}</div>
							<div class="stock">
								<div class="nervous" v-if="j.Stock < 10">库存紧张</div>
								<div class="adequate" v-else>库存充足</div>
							</div>
						</div>
						<div class="w-200">
							<div class="original">￥{{ j.InTaxPrice }}</div>
							<div class="present">￥{{ j.Price }}</div>
						</div>
						<div class="w-200">
							<el-input-number
								@change="blur(j, j['PCount'])"
								@blur="blur(j, j['PCount'])"
								size="mini"
								:disabled="isDisabled"
								v-model="j['PCount']"
								:min="1"
								:max="j.Stock"
								label="描述文字"
							></el-input-number>
						</div>
						<div class="w-200">
							<div class="subtotal">￥{{ (j.Price * j.PCount).toFixed(2) }}</div>
						</div>
						<div class="w-200">
							<div class="del"><i class="iconfont icondustbin_icon" @click="delCardItem(j)"></i></div>
						</div>
					</div>
				</div>
				<div class="bottom">
					<div>
						<div class="w-100" style="cursor: pointer" @click="handleDelAll">删除选中商品</div>
						<div class="w-100" style="cursor: pointer" @click="collection">移入收藏夹</div>
						<div class="w-500" style="cursor: pointer" @click="clearCart">清理购物车</div>
						<div class="w-100">合计(含税费)：</div>
						<div class="total-main">
							<div class="total">￥{{ present.toFixed(2) }}</div>
						</div>
					</div>
					<div class="settlement" @click="settlement">去结算</div>
				</div>
			</div>
		</div>

		<el-dialog title="识别到的商品" :visible.sync="dialogVisible" width="800px">
			<div class="card-top">
				<div class="w-400">商品信息</div>
				<div class="w-200">单价(元)</div>
				<div class="w-200">数量</div>
				<div class="w-200">小计(元)</div>
			</div>
			<div style="width: 100%;height: 50vh;overflow: auto;">
				<div v-for="(item, index) in parseList" :key="index" style="padding: 0 20px;">
					<div class="card-list card-list-inner">
						<div class="w-400" style="display: flex;flex-direction: row;">
							<img style="cursor: pointer;" :src="pic + item.Product.Picture" alt="" />
							<div style="margin-left: 10px;">
								<div class="proName" style="cursor: pointer;width: auto;">{{ item.Product.ProName }}</div>
								<div class="sku">SKU {{ item.Product.BarCode }}</div>
								<div class="stock">
									<div class="nervous" v-if="item.Product.Stock < 10">库存紧张</div>
									<div class="adequate" v-else>库存充足</div>
								</div>
							</div>
						</div>
						<div class="w-200">
							<div class="present">￥{{ item.Product.Price }}</div>
						</div>
						<div class="w-200">{{ item.BuyCount }}</div>
						<div class="w-200">
							<div class="subtotal">￥{{ (item.Product.Price * item.BuyCount).toFixed(2) }}</div>
						</div>
					</div>
				</div>
			</div>

			<span slot="footer" class="dialog-footer" style="width: 100%;display: flex;justify-content: center;">
				<el-button type="primary" @click="synccart" style="width:200px;background-color: #00b5c2;">同步至购物车</el-button>
				<el-button @click="dialogVisible = false" style="width:200px;margin-left: 20px;">取 消</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { getNoLineCart } from '@/views/cart/service';
import { addCollect, getMyCart, removeAllCart, removeCart, getUserInfo } from './service';
import { addCart, getDisList, receiveCoupon } from '../commodity/service';
import { mapState } from 'vuex';
export default {
	data() {
		return {
			url: '',
			plVisible: false,
			dialogVisible: false,
			pic: FILE_URL,
			disList: [],
			dis: {},
			isDisabled: false,
			present: 0,
			discount: 0,
			cartList: [],
			parseList: [],
			fileList: [],
			cardNumber: 0,
			num: '',
			popover: false,
			checked: false
		};
	},
	computed: {
		...mapState(['isLogin'])
	},
	watch: {
		cartList(n) {
			this.present = 0;
			this.cardNumber = 0;
			this.checked = true;
			for (const cartListKey in n) {
				for (const listKey in n[cartListKey].List) {
					this.cardNumber += parseInt(n[cartListKey].List[listKey].PCount);
					if (n[cartListKey].List[listKey].checked) {
						this.present += parseFloat(n[cartListKey].List[listKey].Price) * parseFloat(n[cartListKey].List[listKey].PCount);
					} else {
						this.checked = false;
					}
				}
			}
		},
		isLogin: {
			handler(n) {
				this.$nextTick(async () => {
					this.urlToStorage();

					if (localStorage.getItem('userToken')) {
						this.$store.commit('setLogin', true);
					} else {
						this.$store.commit('setLogin', false);
					}
					await this.init();
					this.initDisList();
				});
			},
			// deep: true,
			immediate: true
		}
	},
	async mounted() {},
	methods: {
		synccart() {
			let cartListTmp = [];
			this.parseList.forEach(tmp => {
				cartListTmp.push({
					code: tmp.Product.BarCode,
					count: tmp.BuyCount
				});
			});

			this.setCartStorage(cartListTmp, 1);
			this.dialogVisible = false;
			this.init();
		},
		upError() {
			this.$message.error('上传失败');
		},
		upSuccess(e) {
			this.dialogVisible = true;
			if (e && e.data && e.data.length) {
				this.parseList = e.data;
				this.fileList = [];
			} else {
				this.$message.error('表格内容读取失败，请检查表格格式是否正确');
			}
			// parseList
		},
		downloadTemplate() {
			this.plVisible = false;
			window.open(URL_AJAX + '/upload/excel/Normal.xlsx');
		},
		urlToStorage() {
			if (this.$route.query && this.$route.query.data) {
				let cartList = this.$route.query.data.split(',');
				if (cartList && cartList.length) {
					let cartListTmp = [];
					for (let i = 0; i < cartList.length; i++) {
						let tmp = cartList[i].split('|');
						if (tmp.length == 2) {
							cartListTmp.push({
								code: tmp[0],
								count: tmp[1]
							});
						}
					}

					if (cartListTmp.length) {
						this.setCartStorage(cartListTmp, 1); //url中的数量要覆盖本地缓存数量
					}
				}
			}
		},
		handleTo(data) {
			let u = localStorage.getItem('u');
			let ID = data.PID ? data.PID : data.ID;
			window.open(`/commodity?pid=${ID}&u=${u}`, '_blank').location;
		},
		blur(data, value) {
			if (!this.isLogin) {
				// 未登录
				let data = [];
				for (let i in this.cartList) {
					for (let j in this.cartList[i].List) {
						data.push(this.cartList[i].List[j].BarCode + '|' + this.cartList[i].List[j].PCount);
					}
				}

				this.$store.commit('add');
				this.cartList.splice(0, 0);
				this.$router.replace({
					path: `/cart?data=${data.join(',')}`
				});
			} else {
				this.isDisabled = true;
				addCart({
					PID: data.PID,
					Count: value
				}).then(() => {
					this.isDisabled = false;
					this.$store.commit('add');
					this.cartList.splice(0, 0);
				});
			}
		},
		// 去结算
		settlement() {
			if (!this.isLogin) {
				this.toCIAMLoginIn();
				// this.$message.success('正在跳转登录');
				// setTimeout(() => {
				// 	let url = encodeURI(window.location.href);
				// 	window.location.href = `${URL_LOGIN}?returnurl=${url}`;
				// }, 1000);
				return;
			} else {
				let data = '';
				for (let i in this.cartList) {
					for (let j in this.cartList[i].List) {
						if (this.cartList[i].List[j].checked) {
							data += this.cartList[i].List[j].PID + '|' + this.cartList[i].List[j].PCount + ',';
						}
					}
				}
				data = data.substr(0, data.length - 1);
				// 登录完成
				if (data) {
					let u = localStorage.getItem('u');
					this.$router.push({
						path: `/settlement?data=${data}&u=${u}`
					});
				} else {
					this.$message.error('请选择商品');
				}
			}
		},
		toLogin() {
			// 登录
			this.toCIAMLoginIn();
			// setTimeout(() => {
			// 	let url = encodeURI(window.location.href);
			// 	window.location.href = `${URL_LOGIN}?returnurl=${url}`;
			// }, 1000);
			return;
		},
		// 购物车全部删除
		clearCart() {
			this.$confirm('你确定要执行吗, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				if (!this.isLogin) {
					localStorage.removeItem('cartList');
					this.$message.success('成功');
					this.$router.push({
						path: `/cart?data=`
					});
					this.init();
				} else {
					// 已经登录
					removeAllCart().then(() => {
						this.isDisabled = false;
						this.$store.commit('add');
						this.cartList.splice(0, 0);
						this.$message.success('成功');
						this.init();
					});
				}
			});
		},
		// 移动到收藏夹
		collection() {
			if (!this.isLogin) {
				this.toCIAMLoginIn('请先登录！');
				// this.$message.error('请先登录！');
				// setTimeout(() => {
				// 	let url = encodeURI(window.location.href);
				// 	window.location.href = `${URL_LOGIN}?returnurl=${url}`;
				// }, 1000);
				return;
			}
			let IDs = '';
			for (const cartListKey in this.cartList) {
				for (const listKey in this.cartList[cartListKey].List) {
					if (this.cartList[cartListKey].List[listKey].checked) {
						IDs += this.cartList[cartListKey].List[listKey].ID + ',';
					}
				}
			}
			if (IDs == '') {
				this.$message.error('请选择商品');
				return;
			}
			IDs = IDs.substr(0, IDs.length - 1);
			addCollect({ PID: IDs }).then(() => {
				this.$message.success('添加成功');
			});
		},
		// 优惠卷展示文本
		changeCoupon(item) {
			this.dis = item;
			this.popover = false;
			this.cartList.splice(1, 0);
		},
		// 优惠卷列表
		initDisList() {
			let parameter = {};
			if (this.isLogin) {
				parameter.UserID = localStorage.getItem('userToken');
			}
			getDisList(parameter).then(res => {
				this.disList = res.data;
			});
		},
		// 领取优惠卷
		handleAddCoupon(data) {
			// 登录
			if (!this.isLogin) {
				this.toCIAMLoginIn('请先登录！');
				// this.$message.error('请先登录！');
				// setTimeout(() => {
				// 	let url = encodeURI(window.location.href);
				// 	window.location.href = `${URL_LOGIN}?returnurl=${url}`;
				// }, 1000);
				return;
			}
			let parameter = {
				DisID: data.ID
			};
			if (data.State == 1) {
				receiveCoupon(parameter).then(() => {
					this.couponVisible = true;
					this.$message.success('领取成功');
					this.initDisList();
				});
			}
		},
		async init() {
			let IsVip = false;
			// 已经登陆请求接口数据
			if (this.isLogin) {
				let cartList = this.getCartStorage();
				await cartList.forEach(function(value, key) {
					addCart({
						Code: value.code,
						Count: value.count
					});
				});
				// 获取用户信息
				getUserInfo().then(res => {
					if (res.data.IsVip == 1) {
						IsVip = true;
					} else {
						IsVip = false;
					}
				});

				setTimeout(() => {
					getMyCart().then(res => {
						this.cardNumber = 0;
						this.checked = true;
						for (let i in res.data) {
							res.data[i].checked = true;
							for (let j in res.data[i].List) {
								res.data[i].List[j].checked = true;
								res.data[i].List[j].Price = IsVip ? res.data[i].List[j].PLUSPrice : res.data[i].List[j].Price;
								this.cardNumber += res.data[i].List[j].PCount;
							}
						}
						this.cartList = res.data;
					});
				}, 500);
				localStorage.removeItem('cartList');
				return;
			}
			// 没登录请求缓存数据

			let barCodes = [];
			let cartList = [];
			if (this.$route.query && this.$route.query.data) {
				//先判断url有没有cart参数，有则以url为准，没有则读取缓存
				let parameter = this.$route.query.data.split(',');
				if (parameter && parameter.length) {
					parameter.forEach(v => {
						let tmp = v.split('|');
						if (tmp.length == 2) {
							barCodes.push(tmp[0]);
							cartList.push({
								code: tmp[0],
								count: tmp[1]
							});
						}
					});
				}
			} else {
				//没读到url，则读缓存
				cartList = this.getCartStorage();
				console.log(cartList);
				cartList.forEach(function(value, key) {
					barCodes.push(value.code);
				});
			}

			if (barCodes.length) {
				getNoLineCart({
					BarCodes: barCodes.join(',')
				}).then(res => {
					this.cardNumber = 0;
					this.checked = true;

					for (let i in res.data) {
						res.data[i].checked = true;
						for (let j in res.data[i].List) {
							res.data[i].List[j].checked = true;
							if (cartList.length) {
								for (let a in cartList) {
									if (res.data[i].List[j].BarCode == cartList[a].code) {
										res.data[i].List[j].PCount = cartList[a].count;
										this.cardNumber += parseInt(res.data[i].List[j].PCount);
									}
								}
							}
						}
					}
					this.cartList = res.data;
				});
			} else {
				this.cartList = [];
			}
		},
		// 购物车多删除
		handleDelAll() {
			// 删除选中商品
			if (this.isLogin) {
				// 已经登录
				let IDs = '';
				for (const cartListKey in this.cartList) {
					for (const listKey in this.cartList[cartListKey].List) {
						if (this.cartList[cartListKey].List[listKey].checked) {
							IDs += this.cartList[cartListKey].List[listKey].ID + ',';
						}
					}
				}
				if (IDs == '') {
					this.$message.error('请选择商品');
					return;
				}
				IDs = IDs.substr(0, IDs.length - 1);
				this.$confirm('你确定要执行吗, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					removeCart({
						IDs: IDs
					}).then(() => {
						this.isDisabled = false;
						this.$store.commit('add');
						this.cartList.splice(0, 0);
						this.$message.success('成功');
						this.init();
					});
				});
			} else {
				// 未登录
				let newList = [];
				for (const cartListKey in this.cartList) {
					for (const listKey in this.cartList[cartListKey].List) {
						if (!this.cartList[cartListKey].List[listKey].checked) {
							newList.push({
								code: this.cartList[cartListKey].List[listKey].BarCode,
								count: this.cartList[cartListKey].List[listKey].PCount
							});
						}
					}
				}
				this.setCartStorage(newList, 2);

				// this.$router.push({
				// 	path: `/cart?data=${this.getCartUrl()}`
				// });
				this.isDisabled = false;
				this.$store.commit('add');
				this.cartList.splice(0, 0);
				this.init();
			}
		},
		// 删除icon点击
		delCardItem(data) {
			// 已经登陆执行接口
			if (this.isLogin) {
				this.$confirm('你确定要执行吗, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					removeCart({
						IDs: data.ID
					}).then(() => {
						this.isDisabled = false;
						this.$store.commit('add');
						this.cartList.splice(0, 0);
						this.$message.success('删除成功');
						this.init();
					});
				});
				return;
			}
			// 没有登陆删除缓存
			this.$router.push({
				path: `/cart?data=${this.getCartUrl()}`
			});
			this.$store.commit('add');
			this.init();
		},
		// 数量改变
		changeInputNumber(currentValue, oldValue, data) {
			if (this.isLogin) {
				if (currentValue == 1) {
					return;
				}
				if (currentValue > oldValue) {
					addCart({
						PID: data.PID,
						Count: 1
					}).then(() => {
						this.init();
					});
				} else {
					addCart({
						PID: data.PID,
						Count: -1
					}).then(() => {
						this.init();
					});
				}
			}
			this.cartList.splice(1, 0);
		},
		changeCheckbox(j, i) {
			i.checked = false;
			let status = true;
			for (const cartListKey in i.List) {
				if (!i.List[cartListKey].checked) {
					status = false;
				}
			}
			if (status) {
				i.checked = true;
			}
			this.cartList.splice(1, 0);
		},
		// 单个全选
		changeCheckboxSingle(data, value) {
			for (const listKey in data.List) {
				data.List[listKey].checked = value;
			}
			this.cartList.splice(1, 0);
		},
		// 全选
		changeCheckboxAll(data) {
			for (const cartListKey in this.cartList) {
				this.cartList[cartListKey].checked = data;
				for (const listKey in this.cartList[cartListKey].List) {
					this.cartList[cartListKey].List[listKey].checked = data;
				}
			}
			this.cartList.splice(1, 0);
		}
	}
};
</script>

<style lang="less" scoped>
.proName {
	width: 300px;
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
}
.is-login {
	padding: 8px 20px;
	background: rgba(0, 151, 186, 0.05);
	border: 1px solid #f4f4f4;
	color: #333333;
	margin-top: 20px;
	font-size: 12px;

	button {
		background: #dc2310;
		border: none;
		color: #ffffff;
		margin-left: 15px;
		padding: 5px 10px;
		cursor: pointer;
		outline: none;
	}
}

.cart-main {
	width: 1240px;
	margin: 30px auto;

	.bottom {
		background: #f5f5f5;
		display: flex;
		margin: 30px 0;
		padding: 5px 0 5px 20px;
		box-sizing: border-box;
		font-size: 12px;
		color: #666666;
		display: flex;
		justify-content: space-between;

		> div {
			display: flex;
		}

		.w-100 {
			margin-top: 17px;
		}

		.w-400 {
			margin-top: 17px;
		}

		.w-500 {
			margin-top: 17px;
			width: 600px;
		}

		.total-main {
		}

		.total {
			margin-top: 7px;
			font-family: 'ct';
			color: #dc2310;
			font-size: 23px;
		}

		.discount {
			color: #dc2310;
			font-size: 12px;
			text-align: right;
		}

		.settlement {
			height: 60px;
			line-height: 60px;
			cursor: pointer;
			font-family: 'ct';
			font-size: 22px;
			background: #dc2310;
			color: #ffffff;
			padding: 0 20px;
			margin: -5px 0;
		}
	}

	.position {
		color: #0097ba;
	}

	.whole {
		margin: 15px 0;
		display: flex;
		justify-content: space-between;
	}

	.whole-left {
		font-family: 'ct';
	}

	.whole-right {
		font-size: 14px;
		color: #666666;
		display: flex;
		height: 30px;
		line-height: 30px;
	}

	.coupon {
		cursor: pointer;

		img {
			margin-left: 20px;
			cursor: pointer;
		}
	}
	.piliang {
		background-color: #00b5c2;
		height: 28px;
		overflow: hidden;
		border: #00b5c2 1px solid;
		padding: 0 16px;
		color: #fff;
		font-size: 16px;
		line-height: 28px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		/deep/ .el-upload:focus {
			color: #fff;
			border-color: #00b5c2;
		}
		.p-ico {
			margin-left: 4px;
		}
	}
}

.coupon-title {
	font-family: 'ct';
	margin-bottom: 10px;
}

.coupon-border {
	height: 1px;
	margin-top: 1px;
	background: #bbbfbe;
	margin: 5px 0;
}

.coupon-list {
	display: flex;
	margin: 10px 0;

	.coupon-list-left {
		width: 81px;
		height: 36px;
		text-align: center;
		line-height: 36px;
		color: #ffffff;
		font-family: 'ct';
		background: url('../../assets/coupon-bg.png') no-repeat;
	}

	.coupon-list-center {
		font-size: 12px;
		margin-left: 10px;

		.coupon-list-center-bottom {
			color: #999999;
			margin-top: 2px;
		}
	}

	.coupon-list-right {
		margin-left: 20px;
		margin-top: 10px;
		font-size: 12px;
		color: #999999;
		text-align: right;

		.receive {
			border: 1px solid #dc2310;
			color: #dc2310;
			padding: 3px 10px;
			cursor: pointer;
		}
	}
}

.card-top {
	font-family: 'ct';
	line-height: 40px;
	height: 40px;
	background: #e5e5e5;
	margin-bottom: 20px;
	padding: 0 20px;
	box-sizing: border-box;
	display: flex;
}
.card-list-inner {
	padding: 20px 0 !important;
}
.w-50 {
	width: 50px;
}

.w-100 {
	width: 100px;
}

.w-150 {
	width: 150px;
}

.w-200 {
	width: 200px;
}

.w-400 {
	width: 400px;
}

.card-content {
	padding: 0 20px;
	box-sizing: border-box;
	font-family: 'ct';
	line-height: 40px;
	height: 40px;
	background: #f9f9f9;
	display: flex;

	i {
		color: #1b98aa;
	}
}

.card-list {
	padding: 20px;
	height: 100px;
	display: flex;

	img {
		width: 100px;
		height: 100px;
		border: 1px solid #e5e5e5;
	}

	.w-50 {
		line-height: 100px;
	}

	.w-400 {
		font-weight: 300;
		color: #333333;
	}

	.sku {
		width: fit-content;
		padding: 2px 10px;
		background: #f5f5f5;
		border: 1px solid #dedede;
		border-radius: 15px;
		color: #333333;
		font-size: 12px;
		margin: 10px 0px;
	}

	.stock {
		> div {
			width: fit-content;
			background: rgba(187, 191, 190, 0);
			color: #dc2310;
			font-size: 12px;
			height: 20px;
			line-height: 20px;
			padding: 0 15px;
			margin-top: 3px;
		}

		.adequate {
			color: #0097ba;
		}
	}

	.w-200 {
		margin-top: 20px;
	}

	.original {
		text-decoration: line-through;
		color: #999999;
	}

	.present {
		margin-top: 5px;
	}

	.input-number {
	}

	.subtotal {
		font-family: 'ct';
		color: #1b98aa;
	}

	.subtotal-info {
		font-size: 12px;
		color: #999999;
	}

	i {
		font-size: 20px;
		cursor: pointer;
	}

	.del {
		margin-top: 10px;
	}
}

.card-list:nth-of-type(even) {
	background: rgba(0, 151, 186, 0.05);
}
</style>
